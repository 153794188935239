import { Button, message } from "antd";
import axios from 'axios';
import React, { useCallback, useState } from "react";
import Config from '../../constants/config';
import ModalForm from "./helpers/modalForm";

const token = localStorage.getItem('token');
const AddEvent = ({getConfiguration, data}) => {
    
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [formRef, setFormRef] = useState(null);

  const handleCreate = () => {
    formRef.validateFields((err, values) => {
      if (err) {
        return;
      }
      console.log(values,'data from eidt')
      handleEdit(values)
      
    });
  };

  const handleEdit = (values, id) => {
    setLoading(true)
    console.log(values)
    const {ghl_api, chatgpt_api, email} = values 
    axios.post(Config.update_configuration, {
      ghl_api,
      chatgpt_api,
      email
    },{
      headers: {
        'content-type': 'application/json',
        //'Authorization' : token
      }
    })
    .then((res) => {
      console.log(res.data);
      if(res.data.status === "connected") {
        message.success("Api key added successfully.");
      }
      setLoading(false)
      formRef.resetFields();
      setVisible(false);
      getConfiguration()
     })
    .catch((res) => {
       console.log(res,'catch')
       message.error('Api key have not added successfully.');
       setLoading(false)
       formRef.resetFields();
       setVisible(false);
     })
  }
 
  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  return (
    <>
      <Button type='primary'  onClick={() => setVisible(true)}>Add Configuration</Button>
      <ModalForm
        ref={saveFormRef}
        formTitle = "Add Configuration"
        visible={visible}
        onCancel={() => setVisible(false)}
        onCreate={() => handleCreate()}
        isLoading={isLoading}
      />
    </>
  );
};

export default AddEvent;