import React from 'react';
import { Row, Col, Layout, Icon, Button, List, Switch, Card } from 'antd';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import Sidebar from '../Navigation/Sidebar';
import Header from '../Navigation/Header';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Config from '../../constants/config';

const token = localStorage.getItem('token');
const { Meta } = Card;
const { Content } = Layout;
class AdminsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      windowHeight: null,
      dataRequested : []
    }
  }
  getAdmins = () => {
    axios.get(Config.get_admins, { headers: {"authorization" : token} })
      .then(({ data }) => {
        const state = { ...this.state }
        state.dataRequested = data;
        this.setState(state);
      }).catch((err) => {
        console.log('error', err);
      })
  }
  componentDidMount() {
    this.setState({ windowHeight: window.innerHeight });
    this.getAdmins();
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  render() {
    return (
      <div className="App">
        <Layout style={{ minHeight: this.state.windowHeight }}>
          <Sidebar collapsed={this.state.collapsed} current="admins" openCurrent="admins"/>
          <Layout>
            <Header collapsed={this.state.collapsed} toggle={this.toggle}/>
            <Content  style={{ padding: 24, background: '#EDF1F3', minHeight: this.state.windowHeight - 112 }}>
              <div type="flex" justify="center" align="middle" style={{ background: '#EDF1F3', padding: 24, minHeight: this.state.windowHeight - 112 }}>
                <Row>
                  <Link to="/add-admin">
                    <Button className="btn-add" style={{fontSize:20,marginBottom:10,float:'right'}} ><Icon type="plus" /> Add New</Button> 
                  </Link> 
                  <Col span={24}>
                    <List
                      itemLayout="horizontal"
                      dataSource={this.state.dataRequested}
                      renderItem={item => (
                        <List.Item className="admins-section">
                          <Card style={{ marginTop: 16 }} 
                            actions={[<span className="admin-list-footer-section">Status : { item.active === true ? "Active" : "Inactive" }</span>]}
                            title={item.mosque}
                          >
                            <Meta
                              className="admins-meta-description"
                              description={ "Email : " + item.email}
                            />
                          </Card>
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}
export default AdminsPage;