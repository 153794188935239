import React from 'react';
import { Row, Col } from 'antd';
import { Form, Input, Layout, Icon, Button, message } from 'antd';
import Select from 'react-select';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import Sidebar from '../Navigation/Sidebar';
import Header from '../Navigation/Header';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Config from '../../constants/config';
import moment from 'moment';

const token = localStorage.getItem('token');
const { Content } = Layout;
const FormItem = Form.Item;
const timezone = [
  { value: '-01:00', label: 'UTC−01:00' },
  { value: '-02:00', label: 'UTC−02:00' },
  { value: '-03:00', label: 'UTC−03:00' },
  { value: '-03:30', label: 'UTC−03:30' },
  { value: '-04:00', label: 'UTC−04:00' },
  { value: '-05:00', label: 'UTC−05:00' },
  { value: '-06:00', label: 'UTC−06:00' },
  { value: '-07:00', label: 'UTC−07:00' },
  { value: '-08:00', label: 'UTC−08:00' },
  { value: '-09:00', label: 'UTC−09:00' },
  { value: '-09:30', label: 'UTC−09:30' },
  { value: '-10:00', label: 'UTC−10:00' },
  { value: '-11:00', label: 'UTC−11:00' },
  { value: '-12:00', label: 'UTC−12:00' },
  { value: '+00:00', label: 'UTC±00:00' },
  { value: '+01:00', label: 'UTC+01:00' },
  { value: '+02:00', label: 'UTC+02:00' },
  { value: '+03:00', label: 'UTC+03:00' },
  { value: '+03:30', label: 'UTC+03:30' },
  { value: '+04:00', label: 'UTC+04:00' },
  { value: '+04:30', label: 'UTC+04:30' },
  { value: '+05:00', label: 'UTC+05:00' },
  { value: '+05:30', label: 'UTC+05:30' },
  { value: '+05:45', label: 'UTC+05:45' },
  { value: '+06:00', label: 'UTC+06:00' },
  { value: '+06:30', label: 'UTC+06:30' },
  { value: '+07:00', label: 'UTC+07:00' },
  { value: '+08:00', label: 'UTC+08:00' },
  { value: '+08:45', label: 'UTC+08:45' },
  { value: '+09:00', label: 'UTC+09:00' },
  { value: '+09:30', label: 'UTC+09:30' },
  { value: '+10:00', label: 'UTC+10:00' },
  { value: '+10:30', label: 'UTC+10:30' },
  { value: '+11:00', label: 'UTC+11:00' },
  { value: '+12:00', label: 'UTC+12:00' },
  { value: '+12:45', label: 'UTC+12:45' },
  { value: '+13:00', label: 'UTC+13:00' },
  { value: '+14:00', label: 'UTC+14:00' }
];
class AddAdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      windowHeight: null,
      successMessage: "",
      fields: {},
      utc_offset: null
    }
  }
  componentDidMount() {
    this.setState({ windowHeight: window.innerHeight });
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  onSelectTimezoneChange(name, value) {
    let fields = this.state.fields;
    fields[name] = value;
    let utc_offset = this.state.utc_offset;
    utc_offset = moment().utcOffset(value.value);
    this.setState({fields, utc_offset : utc_offset._offset});
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var self = this;
        axios.post(Config.register_admin, {
          mosque: values.mosqueName,
          email: values.adminEmail,
          password: values.adminPassword,
          utc_offset : this.state.utc_offset
        },{
          headers: {
            'content-type': 'application/json',
            'Authorization' : token
          }
        }).then(function (response) {
          if(response.data.message === "User Created!") {
            const showMessage = message.success("Admin has been created successfully!");
            setTimeout(showMessage, 3000);
            window.location = "/admins"
          } else if(response.data.message === "Already Registered!") {
            message.error("This Admin already present!");
          }
        }).catch(function (error) {
          if(error.response.status===404) {
            message.error("This Admin already present!");
          }
          if(error.response.status===400) {
            message.error("Admin Email must be a valid email");
          }
          if(error.response.status===201) {
            const showMessage = message.success("Admin has been created successfully!");
            setTimeout(showMessage, 3000);
            window.location = "/admins"
          }
        });
      }
    });
  }
  onReset = () => {
    this.props.form.resetFields();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { successMessage } = this.state;
    return (
      <div className="App">
        <Layout style={{ minHeight: this.state.windowHeight }}>
          <Sidebar collapsed={this.state.collapsed} current="admins" openCurrent="admins"/>
          <Layout>
            <Header collapsed={this.state.collapsed} toggle={this.toggle}/>
            <Content  style={{ padding: 24, background: '#EDF1F3', minHeight: this.state.windowHeight - 112 }}>
              <div type="flex" justify="center" align="middle" style={{ background: '#EDF1F3', padding: 24, minHeight: this.state.windowHeight - 112 }}>
                <Row className="add-admin-section-outer">
                  <Col span={24}>
                    <div className="add-admin-section">
                      <Form method="post"  onSubmit={this.handleSubmit}  type="flex" justify="center" align="middle">
                        <Row gutter={8}>
                          <Col span={24}>
                            <FormItem label="Mosque Name">
                              {getFieldDecorator('mosqueName', {
                                rules: [{ required: true, message: 'Please enter mosque name' }],
                              })(
                                <Input placeholder="Enter Mosque Name"/>
                              )}
                            </FormItem>
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col span={24}>
                            <FormItem label="Admin Email">
                              {getFieldDecorator('adminEmail', {
                                rules: [{ required: true, message: 'Please enter admin email' }],
                              })(
                                <Input placeholder="Enter Admin Email"/>
                              )}
                            </FormItem>
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col span={24}>
                            <FormItem label="Admin Password">
                              {getFieldDecorator('adminPassword', {
                                rules: [{ required: true, message: 'Please enter admin password' }],
                              })(
                                <Input placeholder="Enter Admin Password"/>
                              )}
                            </FormItem>
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col span={24}>
                            <FormItem label="Admin Timezone">
                              {getFieldDecorator('adminTimezone', {
                                initialValue: this.state.fields.timezone,
                                rules: [{ required: true, message: 'Please select admin timezone' }],
                              })(
                                <Select
                                  options={timezone}
                                  onChange={this.onSelectTimezoneChange.bind(this, 'timezone')}
                                  placeholder="Select Timezone"
                                  className="select-timezone"
                                /> 
                              )}
                            </FormItem>
                          </Col>
                        </Row>
                        <FormItem  className="section-submit">
                          <Button className="btn-cancel" onClick={this.onReset}>
                            Cancel
                          </Button>
                          <Button className="btn-save" htmlType="submit">
                            Add
                          </Button>
                        </FormItem>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}
const WrappedAddAdminPage = Form.create()(AddAdminPage);
export default WrappedAddAdminPage;