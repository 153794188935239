import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import { BrowserRouter as Router,  Link, Redirect } from 'react-router-dom';
import '../../assets/css/App.css';
import ghlSyncAppLogo from '../../assets/images/logo.png';


const { Sider } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: props.current,
      openCurrent: props.openCurrent,
      openItem: props.openItem
    }
  }
  handleClick = (e) => {
    this.setState({
      current : this.props.current
    });
  }
  render() {
    const adminType = localStorage.getItem('adminType');
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}
        >
          <div className="sidebar-logo-background">
            <img src={ghlSyncAppLogo} className={!this.props.collapsed ? "dashboard-large-logo" : "dashboard-small-logo"} alt="GHL Sync"/>         
          </div> 

          <div className="sidebar-header">
            { !this.props.collapsed ? <h1>GHL Sync</h1> : <h1>GS</h1> }
          </div>
          
          <Menu defaultSelectedKeys={[this.state.current]} defaultOpenKeys={[this.state.openCurrent]} theme="dark" mode="inline" inlineCollapsed={true}>
            <Menu.Item key="home"><Link to="/"><Icon type="appstore" /><span>Dashboard</span></Link></Menu.Item>
            { adminType !== "super-admin" ? <Menu.Item key="configuration"><Link to="/configuration"><Icon type="home" /><span>Configuration</span></Link></Menu.Item> : "" }
            { adminType !== "super-admin" ? <Menu.Item key="tags"><Link to="/tags"><Icon type="schedule" /><span>Tags</span></Link></Menu.Item> : "" }
            {/*{ adminType === "super-admin" ? <Menu.Item key="admins"><Link to="/admins"><Icon type="usergroup-add" /><span>Admins</span></Link></Menu.Item> : "" }*/}
          </Menu>
      </Sider>   
    );
  }
}

export default Sidebar;
