import React from 'react';
import SideMenu from './helplers/sideMenu';
import {Row, Col, List, Avatar, Button, Skeleton, Card, Icon, Modal} from 'antd';
import {  EditOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import AddTag from './helplers/addTag';
import EditTag from './editTag';
import axios from 'axios';
import Config from '../../constants/config';
import moment from 'moment';


interface DataType {
  key: string;
  email: string;
  tag: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Tag',
    dataIndex: 'tag',
    key: 'tag',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <>
        <a>Edit</a>
        <a>Delete</a>
      </>
    ),
  },
];


const data: DataType[] = [
  {
    key: '1',
    email: 'John Brown',
    tag: ['nice', 'developer'],
  },
  {
    key: '2',
    email: 'Jim Green',
    tag: ['loser'],
  },
  {
    key: '3',
    email: 'Joe Black',
    tag: ['cool', 'teacher'],
  },
];

const token = localStorage.getItem('token');
const confirm = Modal.confirm;
class Tags extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            windowHeight: null,
            dataRequested : [],
            isLoading : false,
            isVisible : false,
            isCompleted : null
        }
    }

    getAllEvents = () => {
      axios.get(Config.get_configuration + '?email=' + localStorage.getItem('adminEmail'), { headers: {"authorization" : localStorage.getItem('token')} })
        .then(({ data }) => {
          console.log(data.result.tag);
          const state = { ...this.state }
          state.dataRequested = data.result.tag;
          this.setState(state);
        }).catch((err) => {
          console.log('error', err);
        })
    }

    componentDidMount() {
      this.setState({ windowHeight: window.innerHeight });
      this.getAllEvents();
    }

    handleDelete = (id,title) =>{
      confirm({
        title: 'Are you sure you want to delete this "'+ title+'" tag ?',
        content: '',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          const headers = {
            'authorization': token
          }
          const data = {
            id: id
          }
          return (
            axios.delete(Config.delete_event, {headers, data})
              .then(({ data }) => {
                window.location.reload()
              })
              .catch((err) => {
                console.log('error', err);
              })
          )  
        },        
        onCancel() {
          console.log('Cancel');
        },
      });
    }

    handleEventStatus = (id,title) =>{
      confirm({
        title: 'Are you sure you want to change the status of "'+ title+'" event ?',
        content: '',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          return (
          axios.patch(Config.event_status, {
            id: id,
            upcoming: false,
          },{
            headers: {
              'content-type': 'application/json',
              'Authorization' : token
            }
          })
           .then((res) => {
            // console.log(res.data.message);
            // message.success(res.data.message);
            window.location.reload()
           })
           .catch((res) => {
             console.log(res,'catch')
            //  message.error('Can not Checkout! Please retry!');
           })
        )},
        onCancel() {
          console.log('Cancel');
        },
      });
    }

    setVisible = () => {
      this.setState({
        isVisible : !this.state.isVisible
      })
    }

    render(){
        const IconDelete = ({ id, title, icon, text, color }) => (
            <span style={{color : color}} onClick={() => this.handleDelete(id, title)}>
              {React.createElement(icon, { style: { marginRight: 8 , color : color} })}
              {text}
            </span>
          );
          const IconStatus = ({ id, title, icon, text, color }) => (
            <span style={{color : color}} onClick={() => this.handleEventStatus(id, title)}>
              {React.createElement(icon, { style: { marginRight: 8 , color : color} })}
              {text}
            </span>
          );
        const EventSchedule = ({startDate, startTime, endDate, meetingID, upcoming}) => (
          <>
           <span style={{color : "black"}} >Start date : {startDate} <br/> Start time : {startTime} <br/> End date : {endDate} <br/>  Meeting link : {meetingID}<br/>  Event Status : {upcoming}</span>
          </>
        )
        
        return(
          <SideMenu current='tags' openCurrent='tags'>
            <div type="flex" justify="center"  style={{ background: '#EDF1F3', padding: 24, minHeight: this.state.windowHeight - 112 }}> 
                <AddTag handleFormSubmit= {this.handleFormSubmit} getAllEvents={this.getAllEvents}/>
                <Row>  
                  <Col span={24}>
                  <Table columns={columns} dataSource={data} />
                    <List
                      itemLayout="vertical"
                      size="large"
                      dataSource={this.state.dataRequested}
                      renderItem={item => (
                        <Card style={{ marginTop: 16 }} >  
                          <List.Item
                            key={item}
                            actions={[
                              <EditTag data={item}  getAllEvents={this.getAllEvents} HandleTest={this.handleTest} handleEdit={this.handleEdit} isLoading={this.state.isLoading} visible={this.state.isVisible} isCompleted ={this.state.isCompleted}/>
                              ,
                              <IconDelete id={item.id} title={item} icon={DeleteOutlined} text="Delete" key="list-vertical-star-o" color = "#FF0000" />
                            ]}
                           >
                            <List.Item.Meta
                              title={<p>{item}</p>}
                             />
                            {  item}
                          </List.Item>
                        </Card>
                        )}
                      />
                  </Col>
                </Row>
            </div>
          </SideMenu>
        )
    }
}

export default Tags