import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const adminType = localStorage.getItem('adminType');
        if (adminType === "super-admin" && roles === 1) {
            return <Component {...props} />
        }
        if (adminType !== "super-admin" && roles === 2) {
            return <Component {...props} />
        }
        else {
            return <Redirect to={{ pathname: '/'}} />
        }
    }} />
)
export default PrivateRoute;