import {
  Card,
  Typography,
  Col,
  Row,
  Button,
  Table,
  Tabs,
  Collapse,
} from "antd";
import { Form, Icon, Input, Divider, message, Spin, Modal, List } from "antd";
import axios from "axios";
import React from "react";
import Config from "../../constants/config";
import AddConfiguration from "./addConfiguration";
import EditConfiguration from "./editConfiguration";
import SideMenu from "./helpers/sideMenu";
import {
  DeliveredProcedureOutlined,
  LoadingOutlined,
  DeleteFilled,
  CheckCircleTwoTone,
  ArrowRightOutlined,
  EditFilled,
} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";

import toast, { Toaster } from "react-hot-toast";

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const FormItem = Form.Item;
const { Title } = Typography;
const confirm = Modal.confirm;
const { Panel } = Collapse;

class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: null,
      errors: "",
      dataRequested: {},
      isLoading: false,
      isVisible: false,
      isCompleted: null,
      profiles: [],
      isRunning: false,
      isSynced: false,
      contacts: [],
      successMsg: "",
      prompts: "",
      prompt: "",
      inputField: "",
      outputField: "",
      hiddenInput: null,
      promptName: null,
      modalData: {
        id: null,
        tag: null,
      },
      profile_id: null,
      inputFieldsOptions: [],
      outputFieldsOptions: [],
      selectedOutputOptions: [],
      saveButtonDisabled: false,
      inputFieldIsValid: true,
      outputFieldIsValid: true,
      activePanel: null,
      selectedValues: [],
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }
  getInputFields = (ghl_api) => {
    axios
      .get(Config.get_ghlCustomInput + "?ghl_api=" + ghl_api, {
        headers: {
          /*"authorization" : TOKEN*/
        },
      })
      .then(({ data }) => {
        const inputFieldsOptions = data;
        const nameArray = inputFieldsOptions.map((item) => {
          return { value: item.fieldKey, label: item.name };
        });
        this.setState({
          inputFieldsOptions: nameArray,
          outputFieldsOptions: nameArray,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  showModal = (record) => {
    this.setState({
      modalData: {
        id: record._id,
        tag: record.tag,
      },
      visible: true,
    });
  };

  callback = (key) => {};

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  getConfiguration = () => {
    axios
      .get(
        Config.get_configuration +
          "?email=" +
          localStorage.getItem("adminEmail") +
          "&user_id=" +
          localStorage.getItem("userId"),
        {
          headers: {
            /*"authorization" : TOKEN*/
          },
        }
      )
      .then(({ data }) => {
        console.log(data);
        const state = { ...this.state };
        state.dataRequested = data.result;
        state.dataRequested.view_chatgpt_api =
          data.result.chatgpt_api?.substring(0, 8) +
          "**********" +
          data.result.chatgpt_api?.substring(
            data.result.chatgpt_api.length - 8
          );
        state.dataRequested.view_ghl_api =
          data.result.ghl_api?.substring(0, 8) +
          "**********" +
          data.result.ghl_api?.substring(data.result.ghl_api.length - 8);
        state.dataRequested.profile = data.profile;
        state.dataRequested.tags = data.tags;
        state.profiles = data.profiles;
        state.dataRequested.promptsList = data.prompts.map((option, index) => {
          return { value: option._id, label: option.name };
        });
        this.setState(state);
        this.getInputFields(this.state.profiles[0].ghl_api);
        console.log(this.state.profiles[0].ghl_api);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  onChangePrompt = (event) => {
    this.setState({ [event.target.name]: event.target.value, errors: "" });
    this.setState({
      promptName: event.target.value,
    });
  };

  onChangeTag = (event) => {
    this.setState({ [event.target.name]: event.target.value, errors: "" });
    this.setState({
      tag: event.target.value,
    });
  };

  onSubmit = (event) => {
    const { tag } = this.state;
    event.preventDefault();
    const headers = {
      headers: { "content-type": "application/json" },
    };
    var self = this;
    axios
      .post(
        Config.add_tag,
        {
          tag: tag.toLowerCase(),
          run_time: 0,
          profile_id: event.target.profile_id.value,
        },
        headers
      )
      .then(function (res) {
        if (res.data.status === "created") {
          self.setState({ errors: "" });
          message.success("Tag added successfully.");
          const state = { ...self.state };
          state.dataRequested.tags = res.data.result;
          self.setState(state);
          self.setState({ tag: "" });
        }
      })
      .catch(function (error) {
        if (error.response.data.status === "Duplicate Key") {
          self.setState({ errors: "Please enter a different tag" });
          message.error("Tag have not added successfully.");
        } else {
          self.setState({ errors: "This tag aready exist." });
          message.error("Tag have not added successfully.");
        }
      });
  };

  onPromptSubmit = (event) => {
    const { promptName, prompt, outputField, hiddenInput } = this.state;
    event.preventDefault();
    const headers = {
      headers: { "content-type": "application/json" },
    };

    if (promptName === null || prompt === null) {
      this.setState({
        errors: "Please Enter prompt value",
      });
    } else {
      if (this.state.inputField !== "" && !this.state.inputField !== "") {
        this.setState({
          saveButtonDisabled: true,
        });
        var self = this;
        axios
          .post(
            Config.add_prompt,
            {
              name: promptName,
              prompt: prompt,
              output: outputField,
              hiddenInput: prompt,
              profileId: event.target.profile_id.value,
            },
            headers
          )
          .then(function (res) {
            if (res.status === 200) {
              window.location.reload();
              self.setState({
                saveButtonDisabled: false,
                promptName: null,
                prompt: "",
                outputField: [],
                hiddenInput: null,
              });

              toast("Prompt added successfully!");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (this.state.inputField === "") {
        this.setState({
          inputFieldIsValid: false,
        });
      } else if (this.state.outputField === "") {
        this.setState({
          outputFieldIsValid: false,
        });
      }
    }
  };

  onRun = (record) => {
    if (this.state.prompts.length === 0) {
      alert("Please select a prompts");
    } else {
      record["prompts_id"] = this.state.prompts;
      const headers = {
        headers: { "content-type": "application/json" },
      };
      var self = this;
      self.setState({ isRunning: true, contacts: [], successMsg: "" });
      axios
        .post(
          Config.run_tag,
          {
            record,
          },
          headers,
          { timeout: 360000 }
        )
        .then(function (res) {
          console.log(res.data);
          //self.setState({isRunning: false});
          self.setState({ isSynced: true });
          if (res.data.status === "success") {
            self.setState({ contacts: res.data.contacts });
            self.setState({ successMsg: res.data.result });
            message.success(res.data.result);
            return self.getConfiguration();
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "Not found") {
            self.setState({ isRunning: false });
            return message.error("Haven't found any contact under this tag");
          }
          self.setState({ isRunning: false });
          return message.error("Something went wrong, Please try again");
        });
    }
  };

  onChangeEdit = (event) => {
    this.setState({
      modalData: {
        id: this.state.modalData.id,
        tag: event.target.value,
      },
    });
  };

  onDelete = (record) => {
    var self = this;
    confirm({
      title: 'Are you sure you want to delete this "' + record.tag + '" tag ?',
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        const headers = {
          "content-type": "application/json",
        };
        const data = {
          id: record._id,
        };
        return axios
          .delete(Config.delete_tag, { headers, data })
          .then(({ data }) => {
            console.log(data);
            if (data.status === "deleted") {
              message.success(record.tag + " tag deleted successfully");
              return self.getConfiguration();
            }
          })
          .catch((err) => {
            console.log("error", err);
            return message.error("Something went wrong, please try again");
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  onSelectPromptsChange(value) {
    this.setState({ prompts: value.value });
  }

  handleModalEdit = (e) => {
    this.setState({
      visible: false,
    });
    const headers = {
      "content-type": "application/json",
    };
    const data = {
      id: this.state.modalData.id,
      tag: this.state.modalData.tag,
    };
    axios
      .patch(Config.edit_tag, { headers, data })
      .then(({ data }) => {
        if (data.status === "Item updated successfully") {
          message.success(
            this.state.modalData.tag + " tag updated successfully"
          );
          return this.getConfiguration();
        }
      })
      .catch((err) => {
        return message.error("Something went wrong, please try again");
      });
  };

  componentDidMount() {
    this.setState({ windowHeight: window.innerHeight });
    this.getConfiguration();
  }

  handleChange = (value) => {
    const fieldsArray = this.state.outputFieldsOptions;

    let tempValue = value.value;

    const updatedFieldsArray = fieldsArray.filter(
      (element) => element.value !== tempValue
    );
    this.setState({
      outputFieldsOptions: updatedFieldsArray,
    });

    let newPrompt = this.state.prompt;
    newPrompt = newPrompt + value.value;
    this.setState({
      inputField: value.value,
      prompt: newPrompt,
      inputFieldIsValid: true,
    });
  };

  outputhandleChange = (value) => {
    this.setState({
      outputField: value.value,
      outputFieldIsValid: true,
    });
  };

  handleTextAreaChange = (e) => {
    console.log(e.target);
    this.setState({
      prompt: e.target.value,
    });
  };

  handlePanelChange = (key) => {
    this.setState({
      activePanel: key,
    });
    console.log(this.state.activePanel);
  };

  handleCheckboxChange(event) {
    const optionValue = event.target.value;
    const isChecked = event.target.checked;
    // Create a copy of the selected values array
    const selectedValues = [...this.state.selectedValues];
    if (isChecked === true) {
      let newPrompt = this.state.prompt;
      newPrompt = newPrompt + optionValue;
      this.setState({
        inputField: optionValue,
        prompt: newPrompt,
        inputFieldIsValid: true,
      });
    }

    // Add or remove the option value from the selected values array based on whether the checkbox is checked or unchecked
    if (isChecked) {
      selectedValues.push(optionValue);
    } else {
      const index = selectedValues.indexOf(optionValue);
      if (index > -1) {
        selectedValues.splice(index, 1);
      }
    }

    // Update the component's state with the new selected values array
    this.setState({ selectedValues });
    console.log(selectedValues);
  }

  render() {
    let promptsList = this.state.dataRequested.promptsList;
    const data = this.state.dataRequested;
    const tags = this.state.dataRequested.tags;
    const profiles = this.state.profiles;
    const isRunning = this.state.isRunning;
    const inputFieldsOptions = this.state.inputFieldsOptions;
    const outputFieldsOptions = this.state.outputFieldsOptions;
    const selectedValues = this.state.selectedValues;
    const columns = [
      {
        key: "tag",
        title: "Tag",
        dataIndex: "tag",
      },
      {
        key: "run_time",
        title: "Run Time",
        dataIndex: "run_time",
      },
      {
        key: "run",
        title: "Run",
        render: (record) => {
          return (
            <>
              {isRunning ? (
                <Spin indicator={antIcon} tip="Running..." />
              ) : (
                <Form
                  method="post"
                  name="userLoginForm"
                  className="form-promts"
                  layout="inline"
                >
                  <FormItem>
                    <Select
                      options={promptsList}
                      onChange={this.onSelectPromptsChange.bind(this)}
                      placeholder="Select Prompts"
                      className="select-timezone"
                      menuPlacement="top"
                      required
                      style={{ marginTop: "5px" }}
                    />
                  </FormItem>

                  <FormItem>
                    <Button
                      onClick={() => this.onRun(record)}
                      htmlType="submit"
                      className="btn-run"
                      block
                    >
                      <DeliveredProcedureOutlined /> Run
                    </Button>
                  </FormItem>
                </Form>
              )}
            </>
          );
        },
      },
      {
        key: "action",
        title: "Action",
        render: (record) => {
          return (
            <>
              <span onClick={() => this.showModal(record)}>
                <Button className="btn-run">
                  <EditFilled />
                </Button>
              </span>
              <span span onClick={() => this.onDelete(record)}>
                <DeleteFilled />
              </span>
            </>
          );
        },
      },
    ];
    return (
      <>
        {isRunning ? (
          <>
            {this.state.isSynced ? (
              <div className="run-spinner">
                <div
                  id="scrollableDiv"
                  style={{
                    height: 400,
                    overflow: "auto",
                    padding: "0 16px",
                    border: "1px solid rgba(140, 140, 140, 0.35)",
                  }}
                >
                  <InfiniteScroll
                    dataLength={this.state.contacts.length}
                    hasMore={this.state.contacts.length < 50}
                    scrollableTarget="scrollableDiv"
                  >
                    <List
                      size="large"
                      header={
                        <div>
                          <CheckCircleTwoTone />
                          {this.state.successMsg}
                        </div>
                      }
                      footer={
                        <div>
                          <Button
                            className="btn-run"
                            block
                            onClick={() =>
                              this.setState({
                                isRunning: false,
                                isSynced: false,
                                contacts: [],
                              })
                            }
                          >
                            Back
                            <ArrowRightOutlined />
                          </Button>
                        </div>
                      }
                      bordered
                      dataSource={this.state.contacts}
                      renderItem={(item, index) => (
                        <List.Item>
                          {index + 1}. {item}
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </div>
              </div>
            ) : (
              <div className="run-spinner">
                <Spin
                  tip="Please be patient while generating the data for GHL fields! It may take 4-5 minutes depending on the Open AI server response!"
                  size="large"
                />
              </div>
            )}
          </>
        ) : (
          <SideMenu current="configuration" openCurrent="configuration">
            <Tabs onChange={this.callback} type="card">
              {profiles.map((profile, index) => {
                const filteredTags = tags.filter(
                  (tag) => tag.profile_id === profile._id
                );
                const view_chatgpt_api =
                  profile.chatgpt_api.substring(0, 8) +
                  "**********" +
                  profile.chatgpt_api.substring(profile.chatgpt_api.length - 8);
                const view_ghl_api =
                  profile.ghl_api.substring(0, 8) +
                  "**********" +
                  profile.ghl_api.substring(profile.ghl_api.length - 8);
                return (
                  <TabPane tab={profile.profile_name} key={index + 1}>
                    <div
                      type="flex"
                      justify="center"
                      align="middle"
                      style={{
                        background: "#EDF1F3",
                        padding: 24,
                        minHeight: this.state.windowHeight - 112,
                      }}
                    >
                      <Row>
                        <Col span={8}>
                          <Card
                            type="inner"
                            title="Configuration"
                            extra={
                              <EditConfiguration
                                data={profile}
                                getConfiguration={this.getConfiguration}
                                HandleTest={this.handleTest}
                                handleEdit={this.handleEdit}
                                isLoading={this.state.isLoading}
                                visible={this.state.isVisible}
                                isCompleted={this.state.isCompleted}
                              />
                            }
                          >
                            <Collapse
                              activeKey={this.state.activePanel}
                              onChange={this.handlePanelChange}
                            >
                              <Panel header="Show Details" key="1">
                                <div style={{ padding: "50px 20px" }}>
                                  <Title level={4}>
                                    GHL API KEY :{" "}
                                    {profile.ghl_api
                                      ? view_ghl_api
                                      : "-----------"}
                                  </Title>
                                  <Title level={4}>
                                    Open AI KEY :{" "}
                                    {profile.chatgpt_api
                                      ? view_chatgpt_api
                                      : "-----------"}
                                  </Title>
                                </div>
                              </Panel>
                            </Collapse>
                          </Card>

                          {profile.profile_active_status === 1 ? (
                            <>
                              <Card type="inner" title="Add Prompts">
                                <div style={{ padding: "50px 20px" }}>
                                  <Form
                                    method="post"
                                    name="userLoginForm"
                                    onSubmit={this.onPromptSubmit}
                                    className="login-form"
                                    type="flex"
                                    justify="center"
                                    align="middle"
                                  >
                                    <Form.Item
                                      name="prompt_name"
                                      label="Prompt Title"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please Enter a prompt-name",
                                        },
                                      ]}
                                    >
                                      <Input
                                        prefix={
                                          <Icon
                                            type="pen"
                                            style={{ color: "rgba(0,0,0,.25)" }}
                                          />
                                        }
                                        placeholder="Enter prompt title"
                                        value={this.state.promptName}
                                        onChange={this.onChangePrompt}
                                      />
                                      <div className="errorMsg">
                                        {this.state.errors}
                                      </div>
                                    </Form.Item>
                                    <Form.Item
                                      name="inputField"
                                      label="GHL Input Fields"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please select an option",
                                        },
                                      ]}
                                    >
                                      <br />

                                      <div
                                        style={{
                                          height: "200px",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        {inputFieldsOptions.map((option) => (
                                          <React.Fragment key={option.value}>
                                            <label>
                                              <input
                                                type="checkbox"
                                                name={option.label}
                                                value={option.value}
                                                checked={selectedValues.includes(
                                                  option.value
                                                )}
                                                onChange={
                                                  this.handleCheckboxChange
                                                }
                                              />{" "}
                                              {option.label}{" "}
                                            </label>
                                          </React.Fragment>
                                        ))}
                                      </div>

                                      {/* <Select
                                        options={inputFieldsOptions}
                                        onChange={this.handleChange}
                                        placeholder="Select custom input field"
                                        menuPlacement="top"
                                      /> */}
                                      {this.state.inputFieldIsValid === true ? (
                                        <p></p>
                                      ) : (
                                        <p style={{ color: "red" }}>
                                          Please select an item
                                        </p>
                                      )}
                                    </Form.Item>
                                    <Form.Item label="Prompt Description">
                                      <Input.TextArea
                                        value={this.state.prompt}
                                        onChange={this.handleTextAreaChange}
                                        placeholder="Enter prompt description"
                                      />
                                      <div className="errorMsg">
                                        {this.state.errors}
                                      </div>
                                    </Form.Item>
                                    <Form.Item
                                      name="outputField"
                                      label="GHL Output Fields"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please select an option",
                                        },
                                      ]}
                                    >
                                      <br />
                                      <Select
                                        options={outputFieldsOptions}
                                        onChange={this.outputhandleChange}
                                        placeholder="Select an output field"
                                        menuPlacement="top"
                                      />
                                      {this.state.outputFieldIsValid ===
                                      true ? (
                                        <p></p>
                                      ) : (
                                        <p style={{ color: "red" }}>
                                          Please select an item
                                        </p>
                                      )}
                                    </Form.Item>
                                    <FormItem>
                                      <Input
                                        name="profile_id"
                                        value={profile._id}
                                        required
                                        hidden
                                      />
                                    </FormItem>
                                    <FormItem>
                                      <Button
                                        type="primary"
                                        disabled={this.state.saveButtonDisabled}
                                        htmlType="submit"
                                        className="login-form-button"
                                      >
                                        {this.state.saveButtonDisabled
                                          ? "Saving..."
                                          : "Save Prompt"}
                                      </Button>
                                      <Toaster />
                                    </FormItem>
                                  </Form>
                                </div>
                              </Card>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col span={16}>
                          <Card
                            bordered={false}
                            style={{ width: "80%", padding: "20px" }}
                          >
                            {profile.profile_active_status === 1 ? (
                              <>
                                <Divider>ADD TAG</Divider>
                                <div className="errorMsg">
                                  {this.state.errors}
                                </div>
                                <Form
                                  method="post"
                                  name="userLoginForm"
                                  onSubmit={this.onSubmit}
                                  className="form-add-tag"
                                  layout="inline"
                                >
                                  <FormItem>
                                    <Input
                                      placeholder="Enter tag"
                                      name="tag"
                                      value={this.state.tag}
                                      onChange={this.onChangeTag}
                                      required
                                    />
                                  </FormItem>
                                  <FormItem>
                                    <Input
                                      name="profile_id"
                                      value={profile._id}
                                      required
                                      hidden
                                    />
                                  </FormItem>
                                  <FormItem>
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      className="login-form-button"
                                    >
                                      Save
                                    </Button>
                                  </FormItem>
                                </Form>
                              </>
                            ) : (
                              <h3 style={{ color: "red" }}>
                                Please login to this profile to Update/Use
                              </h3>
                            )}

                            <Divider dashed />
                            <Divider>TAG LIST</Divider>
                            <Divider dashed />

                            <Table
                              dataSource={filteredTags}
                              columns={columns}
                              pagination={false}
                            />
                          </Card>
                        </Col>
                      </Row>
                      <Modal
                        title="Update Tag"
                        visible={this.state.visible}
                        onOk={this.handleModalEdit}
                        onCancel={this.handleCancel}
                        footer={[
                          <Button key="back" onClick={this.handleCancel}>
                            Cancel
                          </Button>,
                          <Button
                            key="submit"
                            type="primary"
                            onClick={this.handleModalEdit}
                          >
                            Update
                          </Button>,
                        ]}
                      >
                        <Form
                          method="post"
                          name="userLoginForm"
                          className="form-add-tag"
                          layout="inline"
                          id="edit-tag-modal-form"
                        >
                          <FormItem>
                            <Input
                              hidden
                              name="id"
                              value={this.state.modalData.id}
                              required
                            />
                            <Input
                              placeholder="Enter tag"
                              name="tag"
                              value={this.state.modalData.tag}
                              onChange={this.onChangeEdit}
                              required
                            />
                          </FormItem>
                        </Form>
                      </Modal>
                    </div>
                  </TabPane>
                );
              })}
            </Tabs>
          </SideMenu>
        )}
      </>
    );
  }
}
export default Configuration;
