let endpoint = "https://ghl-openai-api.sjinnovation.us/api";
// let endpoint = "http://localhost:8010/api";

export default {
    sign_in: endpoint + '/users/login',
    sign_up: endpoint + '/users/signUp',
    get_configuration: endpoint + '/users/getConfiguration',
    add_profile: endpoint + '/users/add-profile',
    update_configuration: endpoint + '/users/configuration',
    add_tag: endpoint + '/users/add-tag',
    run_tag: endpoint + '/results/runInstantData',
    get_admins: endpoint + '/users/admins', 
    toggle_admin_status: endpoint + '/users/admin/toggleStatus',
    delete_tag: endpoint + '/users/removeTag',
    edit_tag: endpoint + '/users/edit-tag',
    switch_profile: endpoint + '/users/switch-profile',
    get_all_profiles_for_active_user: endpoint + '/users/getUserProfiles',
    get_ghlCustomInput: endpoint+ '/users/get-ghl-input',
    add_prompt : endpoint+ '/users/add-prompt',
}
