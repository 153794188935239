import React from "react";
import { Layout, Icon, Tooltip, Menu, Button, Dropdown } from "antd";
import { BrowserRouter as Router, Link, Redirect } from "react-router-dom";
import SignOut from "../SignOut";
import ghlSyncAppLogo from "../../assets/images/home-logo.png";
import { PlusOutlined, LogoutOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../constants/config";

const { Header } = Layout;
class HeaderSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
      profileLength: 0,
    };
  }
  isAuthenticated() {
    const token = localStorage.getItem("token");
    return token && token.length > 10;
  }
  getUserProfiles() {
    const headers = {
      "content-type": "application/json",
    };
    const data = {
      id: localStorage.getItem("userId"),
    };
    axios
      .post(config.get_all_profiles_for_active_user, { headers, data })
      .then(({ data }) => {
        if (data.status === "found") {
          this.setState({
            profiles: data.profiles,
            profileLength: data.profiles.length,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  handleLogoClick = (e) =>{
    window.location.replace('/');
  }

  handleProfileSwitch = (e) => {
    const id = e.target.id;
    const headers = {
      "content-type": "application/json",
    };
    const data = {
      id: id,
    };
    axios
      .post(config.switch_profile, { headers, data })
      .then(({ data }) => {
        if (data.status === "Switching profile seccessful") {
          this.setState({
            profiles: data.profiles,
            profileLength: data.profiles.length,
          });
          window.location.replace('/');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  componentDidMount() {
    this.getUserProfiles();
}
  render() {
    const isAlreadyAuthenticated = this.isAuthenticated();
    const profiles = this.state.profiles;
    const menu = (
      <Menu>
        {
          profiles.map((profile) => {
            return (
                <Menu.Item key={profile._id}>
                  <p onClick={this.handleProfileSwitch} id={profile._id}>{profile.profile_name}</p>
                </Menu.Item>
            );
          })
        }
      </Menu>
    )
    return (
      <div>
        {!isAlreadyAuthenticated ? (
          <Redirect to={{ pathname: "/" }} />
        ) : (
          <Header
            style={{
              background: "rgba(255, 255, 255, 1)",
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "100%",
            }}
          >
            <img
              src={ghlSyncAppLogo}
              className={
                !this.props.collapsed
                  ? "dashboard-large-logo"
                  : "dashboard-small-logo"
              }
              alt="GHL Sync"
              onClick={this.handleLogoClick}
            />
            <div className="header-right">
              <Button
                block
                className="add-profile"
                onClick={() => {
                  window.location.replace("/profile");
                }}
              >
                <PlusOutlined /> Add Profile
              </Button>
                {
                  profiles.length > 0 ? (
                    <Button block className="current-profile">
                    <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Profiles<Icon type="down" />
                  </a>
                </Dropdown>
              </Button>
                  ) : null
                }

              <Button block onClick={SignOut}>
                <LogoutOutlined /> Log Out
              </Button>
            </div>
          </Header>
        )}
      </div>
    );
  }
}

export default HeaderSection;
