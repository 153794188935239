import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import HomePage from '../Home';
import Configuration from '../Configuration';
import AddProfile from '../AddProfile';
import Tags from '../Tags';
import AdminsPage from '../Admins';
import AddAdminPage from '../Admins/addAdmin';
import PrivateRoute from '../../constants/privateRoute';
import * as ROUTES from '../../constants/routes';

const App = () => (
  	<Router>
      <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route exact path={ROUTES.HOME} component={HomePage} />
      <PrivateRoute  exact path={ROUTES.CONFIGURATION}  roles={2} component={Configuration} />
      <PrivateRoute  exact path={ROUTES.ADD_PROFILE}  roles={2} component={AddProfile} />
      <PrivateRoute  exact path={ROUTES.TAGS}  roles={2} component={Tags} />
      <PrivateRoute exact path={ROUTES.ADMINS} roles={1} component={AdminsPage} />
      <PrivateRoute exact path={ROUTES.ADD_ADMIN} roles={1} component={AddAdminPage} />
      </Router>
);
export default App;