import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router,  Link, Redirect } from 'react-router-dom';

const { Footer } = Layout;
class FooterSection extends React.Component {
	constructor(props) {
	    super(props)
	    this.state = {
	    }
	}
	render() {
		return (
			<footer>
		      <div className="container">
		        <p className="footer">
		          GHL Sync | Powered by <a href="https://www.sjinnovation.com" target="_blank">SJ Innovation</a>
		        </p>
		      </div>
		    </footer>
		);
	}
}
export default FooterSection;