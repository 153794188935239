import { Button, message } from "antd";
import axios from 'axios';
import React, { useCallback, useState } from "react";
import Config from '../../constants/config';
import ModalForm from "./helpers/modalForm";


const token = localStorage.getItem('token');

const EditEvent = ({getConfiguration, data}) => {
  const profileId = data._id;
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [formRef, setFormRef] = useState(null);

  const handleCreate = () => {
    formRef.validateFields((err, values) => {
      if (err) {
        return;
      }
      console.log(values,'data from edit')
      handleEdit(values, data.id)
      
    });
  };

  const handleEdit = (values, id) => {
    setLoading(true)
    console.log(values)
    const {ghl_api, chatgpt_api, email} = values 
    axios.post(Config.update_configuration, {
      ghl_api,
      chatgpt_api,
      email,
      profileId
    },{
      headers: {
        'content-type': 'application/json',
        //'Authorization' : token
      }
    })
    .then((res) => {
      console.log(res.data);
      if(res.data.status === "connected") {
        message.success("Api key updated successfully.");
      }
      setLoading(false)
      formRef.resetFields();
      setVisible(false);
      getConfiguration()
    })
    .catch((res) => {
       console.log(res,'catch')
        if(res.status === "Duplicate Key") {
          message.error('Please enter unique GHL and Open AI key.');
        } else {
          message.error('Api key have not updated successfully.');
        }
       setLoading(false)
       formRef.resetFields();
       setVisible(false);
     })
  }

  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  return (
    <>
      <Button type='primary'  onClick={() => setVisible(true)} disabled={data.profile_active_status === 0 ? true : false}>Update Configuration</Button>
      <ModalForm
        ref={saveFormRef}
        formTitle = "Update Configuration"
        visible={visible}
        onCancel={() => setVisible(false)}
        onCreate={() => handleCreate()}
        isLoading={isLoading}
        initialValue = {data}
      />
    </>
  );
};

export default EditEvent;