import React from "react";
import {Row, Col, Modal, Form, Input, Radio, DatePicker, TimePicker  } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from 'moment';

const ModalFormComponent = ({ visible, onCancel, onCreate, form, formTitle , initialValue, isLoading}) => {
  
  const { getFieldDecorator } = form;
  return (
    <Modal
      visible={visible}
      title={formTitle}
      okText="Submit"
      onCancel={onCancel}
      onOk={onCreate}
      height="80vh"
      width="60%"
      maskClosable={false}
      centered={true}
      confirmLoading={isLoading}

    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Tag">
              {getFieldDecorator("tag", {
                initialValue : initialValue  ? initialValue.tag : '',
                rules: [
                  {
                    required: true,
                    message: "Please enter tag. "
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator("email", {
                initialValue : localStorage.getItem('adminEmail'),
                rules: [
                  {
                    required: false
                  }
                ]
              })(<Input hidden/>)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ModalForm = Form.create({ name: "modal_form" })(ModalFormComponent);

export default ModalForm;