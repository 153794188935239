import React, { useState } from "react";
import { Form, Icon, Input, Button, Row, Col, Divider } from "antd";
import ghlSyncAppLogo from "../../assets/images/logo.png";
import { Link, useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Config from "../../constants/config";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const isInvalid = email === "" || password === "";
  const handleSubmit = (e) => {
    e.preventDefault();
    const headers = {
      headers: { "content-type": "application/json" },
    };
    axios
      .post(
        Config.sign_up,
        {
          email: email,
          password: password,
        },
        headers
      )
      .then(function (response) {
        if (response) {
          toast.success("User Created successfully!");
          setTimeout(() => {
            history.push("/");
          }, 2000);
          setError("");
        }
      })
      .catch(function (error) {
        setError("Unable To Sign UP Please try again");
        console.log(error);
      });
  };
  return (
    <div className="wrapper">
      <div className="container">
        <div type="flex" justify="center" align="middle">
          <Row className="form-login">
            <Toaster />
            <Col span={24}>
              <img
                src={ghlSyncAppLogo}
                className="ghl-sync-app-logo"
                alt="GHL Sync"
              />
              <div className="welcome-administrator-section">
                <h1 className="welcome-administrator-title">
                  Welcome to GHL Sync
                </h1>
              </div>
              <Divider />
            </Col>
            <Col span={24}>
              <Form
                method="post"
                name="userLoginForm"
                className="login-form"
                type="flex"
                justify="center"
                align="middle"
                onSubmit={handleSubmit}
              >
                <div className="errorMsg">{error}</div>
                <Form.Item label="Email">
                  <Input
                    prefix={
                      <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Your Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Password">
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="**********"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    disabled={isInvalid}
                  >
                    Sign Up
                  </Button>
                  <span>
                    Already Have An Account? <Link to="/">Sign In</Link>
                  </span>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
