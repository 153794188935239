import { Card, Typography, Col, Row } from "antd";
import { Form, Icon, Input, Button, Divider, message } from "antd";
import axios from "axios";
import React from "react";
import Config from "../../constants/config";
import SideMenu from "./helpers/sideMenu";

const { Title } = Typography;
const FormItem = Form.Item;
const INITIAL_STATE = {
  profile_name: "",
  ghl_api: "",
  chatgpt_api: "",
  profiles: [],
  errors: "",
};
class AddProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: null,
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({ windowHeight: window.innerHeight });
  }

  onSubmit = (event) => {
    event.preventDefault();
    var self = this;
    const { ghl_api, chatgpt_api, profile_name } = this.state;
    axios
      .post(
        Config.add_profile,
        {
          profile_name,
          ghl_api,
          chatgpt_api,
          email: localStorage.getItem("adminEmail"),
        },
        {
          headers: {
            "content-type": "application/json",
            //'Authorization' : token
          },
        }
      )
      .then((res) => {
        const headers = {
          "content-type": "application/json",
        };

        if (res.data.status === "connected") {
          self.setState({ errors: "" });
          const data = {
            id: res.data.id,
          };
          message.success("Profile created successfully.");
          axios
            .post(Config.switch_profile, { headers, data })
            .then(({ data }) => {
              if (data.status === "Switching profile seccessful") {
                window.location.replace("/dashboard");
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((res) => {
        console.log(res, "catch");
        /*if(res.status === "Profile exist") {
                self.setState({errors: "Profile aready exist with this information"});
            }*/
        if (res.status === "Duplicate Key") {
          self.setState({
            errors: "Please enter unique Profile name, GHL and Open AI key",
          });
        } else {
          self.setState({
            errors: "Invalid GHL or Open AI key",
          });
        }
        // message.error('Profile have not created successfully.');
      });
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value, errors: "" });
  };

  render() {
    const { profile_name, ghl_api, chatgpt_api } = this.state;
    const isInvalid =
      profile_name === "" || ghl_api === "" || chatgpt_api === "";
    return (
      <SideMenu current="configuration" openCurrent="configuration">
        <div
          type="flex"
          justify="center"
          align="middle"
          style={{
            background: "#EDF1F3",
            padding: 24,
            minHeight: this.state.windowHeight - 112,
          }}
        >
          <Row>
            <Col span={24} className="create-profile">
              <Card
                title="Create Profile"
                bordered={false}
                headStyle={{ padding: "20px" }}
                bodyStyle={{ padding: "20px" }}
              >
                Please add your profile.
                <Form
                  method="post"
                  name="userLoginForm"
                  onSubmit={this.onSubmit}
                  className="login-form"
                  type="flex"
                  justify="center"
                  align="middle"
                >
                  <div className="errorMsg">{this.state.errors}</div>
                  <FormItem label="Profile Name">
                    <Input
                      placeholder="Enter your profile name"
                      name="profile_name"
                      value={this.state.profile_name}
                      onChange={this.onChange}
                    />
                  </FormItem>
                  <FormItem label="GHL Api Key">
                    <Input
                      type="password"
                      placeholder="**********"
                      name="ghl_api"
                      value={this.state.ghl_api}
                      onChange={this.onChange}
                    />
                  </FormItem>
                  <FormItem label="Open AI Api Key">
                    <Input
                      type="password"
                      placeholder="**********"
                      name="chatgpt_api"
                      value={this.state.chatgpt_api}
                      onChange={this.onChange}
                    />
                  </FormItem>
                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      disabled={isInvalid}
                    >
                      Save
                    </Button>
                  </FormItem>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </SideMenu>
    );
  }
}

export default AddProfile;
