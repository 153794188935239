import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Radio,
  DatePicker,
  TimePicker,
  Icon,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

const ModalFormComponent = ({
  visible,
  onCancel,
  onCreate,
  form,
  formTitle,
  initialValue,
  isLoading,
}) => {
  const { getFieldDecorator } = form;
  const [showGHLKey, setShowGHLKey] = useState(false);
  const [showOpenAIKey, setShowOpenAIKey] = useState(false);

  const handleShowOpenAIKey = () => {
    setShowOpenAIKey(!showOpenAIKey);
  };
  const handleShowGHLKey = () => {
    setShowGHLKey(!showGHLKey);
  };
  return (
    <Modal
      visible={visible}
      title={formTitle}
      okText="Update"
      onCancel={onCancel}
      onOk={onCreate}
      maskClosable={false}
      centered={true}
      confirmLoading={isLoading}
    >
      <Form layout="vertical">
        <Form.Item label="GHL API KEY">
          {getFieldDecorator("ghl_api", {
            initialValue: initialValue ? initialValue.ghl_api : "",
            rules: [
              {
                required: true,
                message: "Please enter ghl api key",
              },
            ],
          })(
            <Input
              className="mush"
              addonAfter={
                <Icon
                  type={showGHLKey ? "eye-invisible" : "eye"}
                  onClick={handleShowGHLKey}
                />
              }
              type={showGHLKey ? "text" : "password"}
            />
          )}
        </Form.Item>

        <Form.Item label="OPEN AI API KEY">
          {getFieldDecorator("chatgpt_api", {
            initialValue: initialValue ? initialValue.chatgpt_api : "",
            rules: [
              {
                required: true,
                message: "Please enter openai api key",
              },
            ],
          })(
            <Input
              className="mush"
              addonAfter={
                <Icon
                  type={showOpenAIKey ? "eye-invisible" : "eye"}
                  onClick={handleShowOpenAIKey}
                />
              }
              type={showOpenAIKey ? "text" : "password"}
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator("email", {
            initialValue: localStorage.getItem("adminEmail"),
            rules: [
              {
                required: false,
              },
            ],
          })(<Input type="email" hidden />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ModalForm = Form.create({ name: "modal_form" })(ModalFormComponent);

export default ModalForm;
